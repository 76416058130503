<template>
  <section class="content">
    <div class="card text-center shadow-none">
        <div class="card-body">
            <h5 class="card-title mb-4" style="width: 100%;"><img class="" style="width:300px;margin-left:auto;margin-right: auto;" src="../assets/img/logo-tosca.png" alt="Logo"></h5>
            <p class="card-text">
Taruna Cendekia bukan hanya sekedar bimbingan belajar. Taruna Cendekia memberikan Bimbingan Lengkap untuk menemani perjuangan kamu menjadi seorang Abdi Negara baik sekolah kedinasan , TNI, POLRI, maupun CPNS dengan bimbingan terpadu, super lengkap, & berkualitas.</p>

<p>Banyaknya siswa Taruna Cendekia yang berhasil lolos menjadi Abdi Negara merupakan bukti dedikasi Taruna Cendekia sehingga kami layak menjadi terbaik.</p>
        </div>
    </div>
  </section>
</template>
<script>
import { authFetch } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
    created: function () {
    },
    data: () => ({
        form: {
            content: "",
        },
    }),
    methods: {
    },
}
</script>